import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import { Helmet } from "react-helmet";

import PageLayout from "../components/Layout/PageLayout";
import PageBlock from "../components/Layout/PageBlock";
import Breadcrumb from "../components/Breadcrumb/Breadcrumb";

import { Mail, FileText } from "react-feather";
import { PageBlockTitle, staticImgContainer, CTALink, InlineExternalLink } from "../styles/component-stylings";
import { APPLY_LINK, DSC_EMAIL } from "../helpers/links";

const PAGE_TITLE = "Contact us";
const AFP_CONTACT_EMAIL = "https://www.afp.gov.au/contact-us";
const AFP_RECRUITMENT_EMAIL = "afprecruitment@afp.gov.au";
const AFP_TECHNICAL_RECRUITMENT_EMAIL = "technical.recruitment@afp.gov.au";

const ContactPage = ({ pageContext }) => {
    return (
        <PageLayout pageTitle={PAGE_TITLE} pageContext={pageContext}>
            <Helmet>
                <meta name='description' content='Contact Page for Digital Surveillance Collection from the Australian Federal Police' />
            </Helmet>
            <PageBlock pageContext={pageContext} backgroundColour='white' isFirstBlock>
                <Breadcrumb pageContext={pageContext} />
                <h1>{PAGE_TITLE}</h1>
                <StaticImage
                    style={staticImgContainer}
                    imgStyle={{ objectPosition: "center bottom" }}
                    src='../images/contact-us.jpg'
                    alt='Contact Us'
                    placeholder='blurred'
                    loading='lazy'
                />
                <PageBlockTitle>DSC careers</PageBlockTitle>
                <p>
                    Discover more at <Link to='/careers'>careers</Link> and{" "}
                    <InlineExternalLink href={APPLY_LINK} target='_blank' rel='external'>
                        view current vacancies
                    </InlineExternalLink>
                    .
                </p>
                <p>We are always looking for candidates, regardless of posted vacancies.</p>
                <p>If you think you meet our requirements:</p>
                <CTALink href={`mailto:${DSC_EMAIL}?subject=[DSC Recruitment]`} target='_blank' rel='noopener noreferrer'>
                    <span>Email us your CV</span>
                    <FileText />
                </CTALink>

                <PageBlockTitle>DSC-related enquiries</PageBlockTitle>
                <CTALink href={`mailto:${DSC_EMAIL}?subject=[DSC Recruitment Questions]`} target='_blank' rel='noopener noreferrer'>
                    <span>Email us your questions</span>
                    <Mail />
                </CTALink>

                <PageBlockTitle>Dandelion Program enquiries</PageBlockTitle>
                <p>
                    For more information on the Dandelion Program, feel free to email us at{" "}
                    <InlineExternalLink href={`mailto:${AFP_TECHNICAL_RECRUITMENT_EMAIL}`} target='_blank' rel='noopener noreferrer'>
                        {AFP_TECHNICAL_RECRUITMENT_EMAIL}
                    </InlineExternalLink>
                    .
                </p>

                <PageBlockTitle>AFP-related enquiries</PageBlockTitle>
                <p>
                    Visit{" "}
                    <InlineExternalLink href={AFP_CONTACT_EMAIL} target='_blank' rel='external'>
                        the contact page on the AFP website
                    </InlineExternalLink>{" "}
                    or email{" "}
                    <InlineExternalLink href={`mailto:${AFP_RECRUITMENT_EMAIL}`} target='_blank' rel='noopener noreferrer'>
                        {AFP_RECRUITMENT_EMAIL}
                    </InlineExternalLink>
                    .
                </p>
            </PageBlock>
        </PageLayout>
    );
};

export default ContactPage;
